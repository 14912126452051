import { useAppStore } from './modules/app'
import { useLocaleStore } from './modules/locale'

interface TenantInfo {
  /**
   * portal首页是否开启：true/false
   */
  portalHomeSettingFlag: boolean;

  /**
   * portal工作台是否开启：true/false
   */
  portalWorkbenchSettingFlag: boolean;

  /**
   * protal 工作台模版是否可编辑
   */
  portalWorkbenchEditFlag: boolean;

  /**
   * portal我的消息是否开启：true/false
   */
  portalMyMessageSettingFlag: boolean;

  /**
   * portal我的待办是否开启：true/false
   */
  portalMyTodoSettingFlag: boolean;

  /**
   * portal报表中心是否开启：true/false
   */
  portalReportCenterSettingFlag: boolean;
  /**
   * 报表中心第三方地址
   */
  reportCenterUrl: string;

  /**
   * portal轮播图片是否开启：true/false
   */
  portalRotatingImagesSettingFlag: boolean;

  /**
   * protal 消息公告内容展示
   */
  portalMyNoticeSettingFlag: boolean;

  /**
   *  protal 个人中心是否展示
   */
  portalPersonalCenterSettingFlag: boolean;

  /**
   * todoDateFormat (日期格式展示)
   */
  todoDateFormat: string;
  /**
   * 是否开启点击卡片跳转 
   */
  isClickTodoCardJump: boolean;
  /**
   * 是否启用批量操作
   */
  isEnableBatchOperate: boolean;
  /**
   * 是否显示状态
   */
  isShowTodoStatus: boolean;
  /**
   * 侧边栏菜单是否允许展开
   */
  isSidebarAllowShow: boolean;
  /**
   * protal 头部是否显示天气信息
   */
  isShowWeather: boolean;
  /**
   * 默认跳转地址
   */
  firstShowMenu: string;
  /**
   * 是否显示logo (配置使用情况暂不明确)
   */
  showLogo: boolean;
}


// interface TenantConfig {
//     // 主题色配置
//     primaryColor: string;
//     // 布局背景色
//     layoutBgColor: string;
//     // 左侧菜单字体颜色
//     leftMenuTextColor: string;
//     // 头部字体颜色
//     topHeaderTextColor: string;
// }

class Tenant {

  /**
   *
   */
  get noticeIcon() {
    const { portalMyNoticeSettingFlag, portalMyTodoSettingFlag, portalMyMessageSettingFlag } = this.info
    return portalMyMessageSettingFlag || portalMyTodoSettingFlag || portalMyNoticeSettingFlag
  }


  /**
   * 写入租户信息
   */
  setInfo = (info: any) => {
    this.info = info
    const locale = useLocaleStore()
    if (info.languageList) {
      try {
        locale.setLocaleMap(JSON.parse(info.languageList))
      } catch {

      }
    }
    this.setThemeConfig(info.portalLayout, info.themeConfig)
  }

  themeConfig: ThemeTypes = {}


  setThemeConfig = (portalLayout: LayoutType, config: any) => {

    const app = useAppStore()

    app.setLayout(portalLayout)

    if (config) {
      try {
        const data = JSON.parse(config)
        Object.assign(this.themeConfig, data)
        // 主题色调试（配置线上主题色）
        // this.themeConfig = Object.assign(this.themeConfig , {
        //   elColorPrimary: "#53a600",
        //   topHeaderBgColor: "#f5f6f8",
        //   topHeaderTextColor: "#1f2b4a",
        //   leftMenuBgColor: "#53a600",
        //   leftMenuTextColor: "#f5f6f8",
        //   leftMenuBgLightColor: "#6bb225",
        //   leftMenuIconColor: "#f5f6f8",
        //   leftMenuBgActiveColor: "#f5f6f8",
        //   leftMenuCollapseBgActiveColor: "#f5f6f8",
        // } as ThemeTypes)
      } catch {

      }
    }
    if (localStorage.getItem('elColorPrimary')) {
      // 主题色可以修改，走缓存获取
      this.themeConfig.elColorPrimary = localStorage.getItem('elColorPrimary') as string
    }

    app.setTheme(this.themeConfig) // 设置主题色
    app.setCssVarTheme()   // 将主题色写入到变量
    app.changeGaiaTheme();
  }

  info: TenantInfo = {
    portalPersonalCenterSettingFlag: false,
    portalHomeSettingFlag: false,
    portalWorkbenchSettingFlag: false,
    portalWorkbenchEditFlag: false,
    portalMyMessageSettingFlag: false,
    portalMyTodoSettingFlag: false,
    portalReportCenterSettingFlag: false,
    reportCenterUrl: '',
    portalRotatingImagesSettingFlag: false,
    portalMyNoticeSettingFlag: false,
    todoDateFormat: '',
    isClickTodoCardJump: false,
    isEnableBatchOperate: false,
    isShowTodoStatus: false,
    isSidebarAllowShow: false,
    isShowWeather: false,
    firstShowMenu: "home",
    showLogo: false,
  }

}


export default new Tenant()

export const setCookies = (cookies: Record<string, string>) => {
    if (!cookies) return
    Object.entries(cookies).forEach(([key, value]) => {
      // 如果值是对象，序列化它
      if (typeof value === 'object' && value !== null) {
        value = JSON.stringify(value);
      }
      const { hostname } = location;
      
      document.cookie = `${key}=${value || ""}; path=/; domain=.${hostname.split('.').slice(1).join('.')}`;
    });
}

// 删除cookie
export function deleteCookie(name) {
  const { hostname } = location;
  document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${hostname.split('.').slice(-2).join('.')}`;
}

// 获取cookie
export const getCookie = (cookieName) => {
    var allCookies = document.cookie;
    if (allCookies === "")
      return null;
    var cookieArray = allCookies.split("; ");
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      var separatorIndex = cookie.indexOf("=");
      var name = cookie.substring(0, separatorIndex);
      var value = cookie.substring(separatorIndex + 1);
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null;
}
import { getAllAppModuleApi, moduleGetSuperscript } from "@/api/home";
import _ from "lodash-es";
import { getStrDictOptions } from "@/utils/dict";
import { defineStore } from "pinia"

interface AppConfigItem {
    appCode: string;
    appName: string;
    routePrefix: string;
}

interface ModuleItem {
    appCode: string;
    moduleCode: string;
    moduleDesc: string;
    moduleName: string;
    routeUrl: string;
}
interface LocaleState {
    appNode: Record<string, AppConfigItem>;
    list: any[];
    allList: any[]; // 全局的应用list
    badgeNode: Record<string, number>;
    moduleNode: Record<string, ModuleItem>;
}

export const useAppModuleStore = defineStore('appModule', {
    state: (): LocaleState => {
        return {
            appNode: {},
            moduleNode: {},
            badgeNode: {},
            list: [],
            allList: [],
        }
    },
    getters: {

    },
    actions: {
        setAppList(list: AppConfigItem[]){
            for (const item of list) {
                this.appNode[item.appCode] = item;
            }
        },
        async setAllAppList(data){
            // 获取全部应用分类
            const options: any[] = await new Promise((resolve) => {
                const keys = getStrDictOptions('front_category_app_type');
                if(!keys.length) {
                    // 字典获取失败，延迟获取
                    setTimeout(() => {
                        resolve(getStrDictOptions('front_category_app_type'))
                    }, 1000)
                }else {
                    resolve(keys);
                }
            });
            const objNode = {};
            for (const item of options) {
                objNode[item.value] = {
                    appCode: item.value,
                    appName: item.label,
                    appNameLangkey: item.label,
                    moduleList: [],
                }
            }

            for (const app of data) {
                if(app.moduleList){
                    for (const item  of app.moduleList) {
                        if(item.frontCategoryCode && objNode[item.frontCategoryCode]){
                            objNode[item.frontCategoryCode].moduleList.push(item);
                        }
                    }
                }
            }


            this.allList = Object.values(objNode).filter((o: any) => o.moduleList.length);
        },
        // 获取模块角标信息
        getSuperscript(){
            const moduleKeys = this.list.reduce((arr, item) => { return arr.concat(item.moduleList.map((o) => o.moduleCode)) }, [])
            console.log('getSuperscript', this.list)
            moduleGetSuperscript(moduleKeys).then((res) => {
                // "code": 0,
                // "data": [
                //     {
                //         "moduleCode": "authoritys.role.list",  // 模块编号
                //         "superscriptCtn": 0  // 数量
                //     },
                const map = new Map(res.data.map((o) => [o.moduleCode, o.superscriptCtn]));
                this.badgeNode = moduleKeys.reduce((obj, key) => {
                    return { ...obj, [key]: map.get(key) || 0 }
                }, {})
            })
        },
        getAllAppModuleApi(){
            getAllAppModuleApi().then((res) => {
                this.list = _.cloneDeep(res.data);
                for (const item of res.data) {
                    for (const option of item.moduleList) {
                        this.moduleNode[option.moduleCode] = option;
                    }
                }
                this.setAllAppList(res.data);
                // 获取角标信息
                this.getSuperscript();
            })
        }
    }
})